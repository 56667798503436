import React from 'react';
import { graphql } from 'gatsby';
import Layout, { Content } from './components/Layout';
import ContentNavigation from './components/Navigation/Content';
import styled from 'styled-components';
import { SubHeadline } from './components/common/Headline';

const TermsText = styled.div`
  white-space: pre-line;
`;

export const query = graphql`
  query TermsConditionsQuery($id: Int!) {
    strapiProgrammes(strapiId: { eq: $id }) {
      strapiId
      Title
      Theme
      Terms
    }
  }
`;

const TermsAndConditions = ({ data, pageContext, location }) => {
  const programme = data.strapiProgrammes;
  const { Terms: termsAndConditions } = programme;
  const navigation = pageContext.navigation;

  const theme = programme.Theme || 'default';

  return (
    <Layout title={'Terms and Conditions'}>
      <ContentNavigation
        theme={theme}
        links={navigation}
        currentPathName={location.pathname}
      />
      <Content>
        <SubHeadline>Terms &amp; Conditions for {programme.Title}</SubHeadline>
        <br />
        <TermsText dangerouslySetInnerHTML={{ __html: termsAndConditions }}></TermsText>
      </Content>
    </Layout>
  );
};

export default TermsAndConditions;
